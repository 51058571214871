import Swiper from 'swiper'
import { Pagination, Scrollbar, Mousewheel } from 'swiper/modules'
class RelatedProducts extends HTMLElement {
  constructor() {
    super()

    this.productItems = this.querySelectorAll('.js-product-item')
    this.productDetails = this.querySelectorAll('.js-item-detail')
    this.columnGapDesktop = Number(this.dataset.columnGapDesktop)
    this.columnGapMobile = Number(this.dataset.columnGapMobile)

    if (this.productItems.length > 0) {
      this.setInitialActiveState()
    }

    if (this.productItems.length && this.productDetails.length) {
      this.setupEventListeners()
    }

    this.carousel = this.querySelector('[data-carousel]')
    this.isSection = this.dataset?.['section']
    this.carouselCenter = this.dataset?.carouselAlignemnt === 'center' && this.productItems.length > 2
    this.config = this.getSwiperConfig()

    this.initSwiper()
  }

  setInitialActiveState() {
    const firstItem = this.productItems[0]
    const firstDetailId = firstItem.getAttribute('data-id');
    const firstDetail = this.querySelector(`#product-${firstDetailId}`);
    firstItem.classList.add('active')

    if (firstDetail) {
      firstDetail.setAttribute('aria-selected', 'true')
      firstDetail.style.display = 'block';
      firstDetail.classList.add('active')
    }
  }

  getSwiperConfig() {
    // Default configuration
    const defaultConfig = {
      modules: [Pagination, Scrollbar, Mousewheel],
      slidesPerView: 2.6,
      spaceBetween: 15,
      breakpoints: {
        1280: {
          slidesPerView: 4,
          spaceBetween: 2
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true,
        snapOnRelease: true,
      },
      freeMode: true,
      grabCursor: true,
      resistance: false,
      freeModeMomentumBounce: false,
      mousewheel: true,
    }

    // Override with section-specific configuration if applicable
    if (this.isSection) {
      return {
        ...defaultConfig,
        slidesPerView: this.productItems.length > 1 ? 1.27 : 1,
        centeredSlides: false,
        spaceBetween: this.columnGapMobile,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            initialSlide: this.productItems.length > 2 && this.carouselCenter,
            spaceBetween: this.columnGapDesktop
          },
          1024: {
            slidesPerView: 3,
            loop: false,
            initialSlide: 0,
            spaceBetween: this.columnGapDesktop
          },
          1280: {
            slidesPerView: 4,
            loop: false,
            spaceBetween: this.columnGapDesktop
          }
        }
      }
    }

    return defaultConfig;
  }

  setupEventListeners() {
    this.productItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        this.resetActiveState(item)
        this.showFlavorDetail(item)
      })
      item.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault()
          this.resetActiveState(item)
          this.showFlavorDetail(item)
        }
      })
    })
  }

  showFlavorDetail(selectedItem) {
    const productId = selectedItem.getAttribute('data-id')

    // Hide all detail sections
    this.productDetails.forEach((detail) => {
      detail.style.display = 'none'
    })

    if (!selectedItem.classList.contains('active')) return

    // Show selected detail
    const selectedDetail = this.querySelector(`#product-${productId}`)
    if (selectedDetail) {
      selectedDetail.style.display = 'block'
    }

    // Update aria-selected state and active class
    this.productDetails.forEach((el) => {
      el.setAttribute('aria-selected', 'false')
      el.classList.remove('active')
    })
  }

  async initSwiper() {
    if (this.carouselCenter) {
      const wrapper = this.carousel.querySelector('.swiper-wrapper')
      const originalSlides = [...wrapper.children]
      const slideCount = originalSlides.length

      const updateSlidesForMobile = () => {
        // Clear duplicated slides if present
        wrapper.innerHTML = ''
        originalSlides.forEach((slide) => wrapper.appendChild(slide.cloneNode(true)))

        if (window.innerWidth < 768 && slideCount <= 3 || window.innerWidth < 1024 && slideCount == 3) {
          originalSlides.forEach((slide) => wrapper.appendChild(slide.cloneNode(true)))
        }
      }

      // Initial check
      // updateSlidesForMobile()

      // Initialize Swiper
      const swiper = new Swiper(this.carousel, this.config)

      // Listen for resize events
      window.addEventListener('resize', () => {
        // updateSlidesForMobile()
        swiper.update()
      })
    } else {
      const swiper = new Swiper(this.carousel, this.config)
    }
  }

  resetActiveState (el) {
    if (!el.classList.contains('active')) {
      this.productItems.forEach((item) => {
        item.classList.remove('active')
      })
    }
    el.classList.toggle('active')
  }
}

window.customElements.define('related-products', RelatedProducts)